.stepItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepItem::before {
  position: absolute;
  width: 100%;
  top: 20px;
  left: -50%;
}

.stepItem::after {
  position: absolute;
  width: 100%;
  top: 20px;
  left: 50%;
}

.stepCounter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #eeeeee;
  margin-bottom: 6px;
  border: 2px solid #b1b2b6;
}

.checkMark {
  transform: rotate(45deg);
  height: 12px;
  width: 5px;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin-right: 0px;
}

.crossMark {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin: 0px;
  padding-top: 0px;
  margin-top: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stepCounter.active {
  border: 2px solid #1d70b8;
  background-color: #ffffff;
}

.stepItem.completed .stepCounter {
  background-color: #1d70b8;
  border: 2px solid #1d70b8;
}

.stepCounter.uploadError {
  background-color: #d4351c;
  border: 2px solid #d4351c;
}

.stepName {
  font-weight: 700;
  font-size: 16px;
  line-height: calc(20 / 16);
  @media only screen and (min-width: 641px) {
    font-size: 19px;
    line-height: calc(25 / 19);
  }
  @media print {
    font-size: 14px;
    line-height: 1.15;
  }
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
