.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 100%;
  background-color: #eee;
  font-family: Arial, Helvetica, sans-serif;
  .documentNumbers,
  .pageNumbers,
  .saveStatus {
    min-width: 10rem;
  }
}
