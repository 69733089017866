.container {
  label {
    max-width: 39.375rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    select {
      width: 100%;
    }
  }
  .containerWithGreySelect {
    select {
      width: 100%;
    }
  }
}

.containerWithGreySelect {
  label {
    max-width: 39.375rem;
  }
  select {
    background-color: #efefef;
  }
}
