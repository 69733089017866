$header-height: 50px;
$bundle-information-bar-height: 56px;
$document-information-padding: 19.5px;
$document-information-width: 310px;
$max-width-tablet: 1368px;
$document-information-container-width-tablet: 250px;

.gridContainer {
  width: 100%;
  display: flex;

  .documentViewerContainer {
    flex-grow: 1;
  }

  .documentInformationContainer {
    height: calc(
      100vh - $header-height - $bundle-information-bar-height -
        ($document-information-padding * 2)
    );
    max-width: $document-information-width;
    min-width: $document-information-width;
  }

  .containerWithoutKeyEvents {
    height: calc(100vh - $header-height - ($document-information-padding * 2));
    div:nth-child(1) {
      margin-top: unset;
    }
  }

  @media (max-width: $max-width-tablet) {
    .documentInformationContainer {
      max-width: $document-information-container-width-tablet;
      min-width: $document-information-container-width-tablet;
    }
  }
}

.tabs {
  section {
    padding: 0px;
  }
}

.tabList {
  border-bottom: none !important;
  width: 100%;
  min-width: 50%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: #dedede;
  margin-top: -5px !important;
  overflow: hidden;
  height: 34px;

  li {
    width: 50%;
  }

  .tab {
    background-color: #dedede;
    min-width: 50%;
    width: 100%;
    position: relative;
    padding: 0;
    height: 30px;
    padding-top: 3px;
    outline: none !important;
    border-bottom: 1px solid #7c8185;
  }
}

.activeTab {
  background-color: #efefef !important;
}
