.row {
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  min-width: 20rem;
  .name {
    display: flex;
    width: 32rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .spinner {
    min-width: 1.5rem;
    display: flex;
    justify-content: flex-end;
  }
  .progress {
    display: flex;
    justify-content: center;
    width: 4rem;
  }
  .errorIcon {
    display: flex;
    justify-content: center;
    width: 4rem;
    color: #d3351b;
    height: 1.5rem;
  }
}
