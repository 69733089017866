.headerWrapper {
  display: flex;
  justify-content: center;
  background-color: #0b0c0c;

  .headerInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 960px;
    width: 100%;
    height: 50px;
    margin-right: max(15px, calc(15px + env(safe-area-inset-right)));
    margin-left: max(15px, calc(15px + env(safe-area-inset-left)));

    @media only screen and (min-width: 641px) {
      margin-right: max(30px, calc(15px + env(safe-area-inset-right)));
      margin-left: max(30px, calc(15px + env(safe-area-inset-left)));
    }

    .headerLogo {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      text-decoration: none;
      text-decoration-skip-ink: none;

      @media only screen and (min-width: 641px) {
        font-size: 30px;
      }

      &:hover {
        color: #ffffff;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        text-underline-offset: 0.1em;
        text-decoration-thickness: 3px;
      }

      &:active {
        text-decoration: underline;
        text-decoration-skip-ink: none;
        text-underline-offset: 0.1em;
        text-decoration-thickness: 3px;
      }

      &:focus {
        color: #0b0c0c;
        outline: 3px solid #fd0;
        outline-offset: -1px;
        background-color: #fd0;
        border: none;
        text-decoration: none;
        box-decoration-break: clone;
      }
    }

    .headerNavigation {
      display: flex;
      align-self: stretch;
      position: relative;
      margin-right: -15px;

      @media only screen and (min-width: 641px) {
        margin-right: -30px;
      }

      @media only screen and (min-width: 960px) {
        margin-right: 0;
      }

      .headerGlobalLoadingIndicator {
        margin: 5px 10px 0px 0px;
      }

      .headerSignInButton,
      .headerNavigationToggleButton {
        display: block;
        position: relative;
        align-self: stretch;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        font-weight: 550;
        text-decoration: none;
        text-decoration-skip-ink: none;

        @media only screen and (min-width: 641px) {
          font-size: 16px;
        }

        &:after {
          content: '';
          height: 3px;
          left: 20px;
          position: absolute;
          right: 20px;
          bottom: 0;
          top: auto;
          background: none;
        }

        span {
          border-left: 1px solid #7c8185;
          padding: 6px 20px;
        }

        &:not(.headerSignInButton) {
          span {
            &:before {
              content: '';
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;
              display: inline-block;
              height: 8px;
              margin: 0 10px 0 2px;
              vertical-align: middle;
              width: 8px;
              transform: translateY(-35%) rotate(45deg) scale(1);
            }
          }
        }

        &:hover:not(.headerNavigationToggleButtonIsOpen) {
          color: #b1b4b6;

          &:after {
            background: #b1b4b6;
          }

          span {
            &:before {
              border-color: #b1b4b6;
            }
          }
        }

        &:not(.headerNavigationToggleButtonIsOpen):focus {
          color: #0b0c0c;

          &:after {
            background: #0b0c0c;
          }

          span {
            &:before {
              border-color: #0b0c0c;
            }
          }
          &:focus {
            color: #0b0c0c;
            outline: 1px solid #fd0;
            outline-offset: -1px;
            background-color: #fd0;
            text-decoration: none;
          }
        }

        &:focus {
          outline: none;
        }
      }

      .headerNavigationToggleButtonIsOpen {
        background: #f3f2f1;
        color: #1d70b8;

        &:after {
          background: #1d70b8;
        }

        &:not(.headerSignInButton) {
          span {
            border-color: #f3f2f1;

            &:before {
              border-color: #1d70b8;
              transform: rotate(225deg);
            }
          }
        }

        &:not(.headerSignInButton):focus {
          &:after {
            background: #0b0c0c;
          }
          span {
            &:before {
              border-color: #0b0c0c;
            }
          }
        }
      }

      .headerNavigationMenu {
        position: absolute;
        top: 50px;
        right: 0;
        min-width: 220px;
        background: #f3f2f1;
        text-align: left;
        padding: 20px;
        font-size: 14px;
        font-weight: 600;
        z-index: 100;
        @media only screen and (min-width: 641px) {
          font-size: 16px;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            p {
              margin: 5px 0 0 0;
            }

            &:last-child {
              margin-bottom: -20px;
            }
          }
        }

        .link {
          display: block;
          position: relative;
          width: 100%;

          &:after {
            content: '';
            bottom: -24px;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
            height: 24px;
          }
        }
      }
    }
  }
}

.headerBottomBorder {
  border-bottom: 10px solid #1d70b8;
  max-width: 960px;
  margin: 0 auto;
  width: calc(100% - 30px);

  @media only screen and (min-width: 641px) {
    width: calc(100% - 60px);
  }
}
