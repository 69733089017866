$visibility-milliseconds: 2000ms;
$header-height: 50px;
$bundle-information-bar-height: 60px;
$tab-height: 15px;
$tab-padding: 8px;
$footer: 38px;

.webViewer {
  height: calc(
    100vh - $header-height - $bundle-information-bar-height - $tab-height -
      $tab-padding - $footer
  );
  visibility: hidden;
  animation: cssAnimation 0s ease-in $visibility-milliseconds forwards;
}
@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}
