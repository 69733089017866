.container {
  // max-width: 39.375rem;
  /* Remove right hand margin when error is triggered */
  textarea {
    width: 100% !important;
  }
  label {
    margin-right: 0;
    font-weight: bold;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    width: 100%;
  }
}
