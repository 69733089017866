$link-focus-color: #fedd00;

.linkButton {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  text-align: inherit;
  color: #1d70b8;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-decoration: underline;
  cursor: pointer;
  color: #1d70b8;

  &:hover {
    color: #003078;
    text-decoration: underline;
    text-underline-offset: 0.1em;
    text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
    text-decoration-skip-ink: none;
  }

  &:active {
    color: #0b0c0c;
  }

  &:focus {
    background-color: $link-focus-color;
    color: #0b0c0c;
    border-bottom: 3px solid #0b0c0c ;
    margin-bottom: -3px;
    outline: none;   
    text-decoration: none;
  }
}
