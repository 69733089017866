.panel {
  font-weight: 400;
  font-size: 16px;
  line-height: calc(20 / 16);
  @media only screen and (min-width: 641px) {
    font-size: 19px;
    line-height: calc(25 / 19);
  }
  @media print {
    font-size: 14px;
    line-height: 1.15;
  }
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 25px 25px 25px 25px;
  border: 5px solid transparent;
  text-align: center;
  @media only screen and (min-width: 641px) {
    padding: 35px 35px 35px 35px;
  }
  color: #ffffff;
  background: #00703c;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title {
  margin-top: 0px;
  margin-bottom: 30px;
  :last-child {
    margin-bottom: 0px;
  }
  font-weight: 700;
  font-size: 32px;
  line-height: calc(35 / 32);
  @media only screen and (min-width: 641px) {
    font-size: 48px;
    line-height: calc(50 / 48);
  }
  @media print {
    font-size: 32px;
    line-height: 1.15;
  }
}
.body {
  font-weight: 300;
  font-size: 24px;
  line-height: calc(25 / 24);
  @media only screen and (min-width: 641px) {
    font-size: 36px;
    line-height: calc(40 / 36);
  }
  @media print {
    font-size: 24px;
    line-height: 1.05;
  }
}
