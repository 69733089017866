.app {
  h2 {
    color: grey;
  }

  .content {
    min-height: calc(100vh - 171px);
  }

  .contentWithoutBlueBar {
    min-height: calc(100vh - 161px);
  }

  .footer {
    width: 100%;
    a:focus {
      color: #0b0c0c;
      border-bottom: 3px solid #0b0c0c;
      margin-bottom: -3px;
      outline: none;
      text-decoration: none;
    }
  }
}
