$header-height: 50px;
$bundle-information-bar-height: 56px;
$max-width-tablet: 1368px;

.container {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(min-content, max-content) 2fr;

  .documentListSideBarContent {
    padding-top: 0.5rem;
    background-color: #efefef;
    width: 100%;

    .documentListTopPanelContainer {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .documentListContainer {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .errorContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 5px #d3351b;
      margin: 0px 18px 10px 18px;
      height: 60px;
      color: #d3351b;
      background-color: rgb(260, 240, 245);
      h1 {
        text-decoration: underline;
        font-size: 19px;
        margin-left: 6px;
      }
    }

    @media (max-width: $max-width-tablet) {
      .errorContainer {
        margin: 5px 16px 5px 6px;
        padding-left: 6px;
      }
    }
  }

  .documentViewer {
    flex-grow: 2;
  }

  .documentEditor {
    justify-self: flex-end;
  }
}
