.container {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;

  .informationContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    padding: 1rem;
    line-height: 1.7rem;

    .informationHeader {
      width: 100%;
      margin-top: 0rem;
    }

    .informationText {
      margin: 0;
      width: 70%;
    }
  }
}
