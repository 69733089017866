.backLink {
  margin-bottom: 20px;
  a:focus {
    border-bottom: 3px solid #0b0c0c;
    margin-bottom: 13px;
    outline: none;
    text-decoration: none;
  }
}

.tableContainer {
  text-align: center;
  padding-right: 1rem;
  padding-bottom: 0;
  margin-top: 1rem;
  width: 100%;
  min-height: auto;
  background-color: #fff;
  table {
    caption {
      height: 1px;
      border: none;
      width: 1px;
      position: absolute;
      overflow: hidden;
      top: -10px;
    }
  }
}

.submitButtonGroup {
  display: flex;
  margin-top: 2.5em;
}

.spinner {
  margin-left: 10px;
  margin-top: 5px;
}

.errorContainer {
  margin-top: 20px;
  padding: 25px 25px 25px 25px;
  border: solid 5px #d3351b;
  max-width: 46.4rem;
  p {
    color: #d3351b;
    text-decoration: underline;
    font-size: 19px;
  }
}
