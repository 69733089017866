.container {
  text-align: center;
}

.errorSummary {
  color: #0b0c0c;

  padding: 15px;

  &:focus {
    outline: 3px solid #ffdd00;

    outline-offset: 0;
  }

  @media only screen and (min-width: 641px) {
    padding: 20px;
  }

  margin-bottom: 20px;

  @media only screen and (min-width: 641px) {
    margin-bottom: 30px;
  }
}

.withBorder {
  border: 4px solid #d4351c;
}

.withoutBorder {
  border: none;
}

.errorSummaryParagraph {
  font-family: 'nta', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  margin-bottom: 5px;
  text-decoration: none;
  text-decoration-skip-ink: none;
  text-transform: none;
  font-size: 16px;
  line-height: 1.25;
  :visited {
    color: #d4351c;
  }
  @media only screen and (min-width: 641px) {
    font-size: 19px;
    line-height: 1.3;
  }
  color: #d4351c;
  padding-top: 4px;
  padding-bottom: 2px;
  &:hover {
    cursor: default;
  }
}
