$header-height: 50px;
$bundle-information-bar-height: 56px;
$document-information-top-panel-height: 80px;
$document-list-padding: 8px;
$max-width-tablet: 1368px;
$container-width-tablet: 260px;
$bundle-error-height: 81px;
$key-event-tab-height: 34px;
$key-event-header-height: 87px;

.container {
  text-align: center;
  overflow-x: auto;
  background-color: #efefef;
}

.containerHeight {
  height: calc(
    100vh - $header-height - $bundle-information-bar-height -
      $document-information-top-panel-height - $document-list-padding
  );
}
.containerHeightWithError {
  height: calc(
    100vh - $header-height - $bundle-information-bar-height -
      $document-information-top-panel-height - $document-list-padding -
      $bundle-error-height
  );
}

.disabled {
  pointer-events: none;
}

@media (max-width: $max-width-tablet) {
  .container {
    width: $container-width-tablet;
    .documentListItemContainer {
      padding: 0.15rem 0.25rem;
    }
  }
}
