$govuk-border-colour: #b1b4b6;
$govuk-focus-colour: #ffdd00;
$drag-handle-colour: #667080;
$tint-red-background: #fcd7d2;
$tint-red-colour: #932915;
$govuk-brand-colour: #1d70b8;
$white: #fff;

$transparent: #ffdd0000;
$drag-milliseconds: 400ms;
$outline-milliseconds: 100ms;

$max-width-tablet: 1368px;
$container-width-tablet: 238px;

.container {
  display: inline-block;
  padding: 0px 8px;
  width: 260px;
  height: fit-content;
  max-height: 11.5rem;
  background-color: $white;
  border: 1px solid $govuk-border-colour;
  border-radius: 3px;
  box-shadow: 0 4px 6px -2px $govuk-border-colour;
  transition: transform $drag-milliseconds;
  transition: outline $outline-milliseconds;
  margin-bottom: 20px;
}

.keyEventInformationContainer {
  padding-left: 4px;
  padding-right: 4px;
  overflow: hidden;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  &:focus {
    outline: $govuk-focus-colour solid 3px;
  }
}

.dateContainer {
  min-width: 5rem;
}

.dateContainer,
.statusContainer {
  font-weight: bold;
  line-height: 2.4rem;
  width: 15rem;
  max-width: min-content;
  overflow: hidden;
  text-overflow: ellipsis;
  height: fit-content;
}

.shortStatusContainer {
  margin-left: 4rem;
}

.longStatusContainer {
  margin-left: 1.5rem;
}

.firstRowContainer {
  white-space: nowrap;
  display: flex;
  height: fit-content;
  padding-top: 4px;
}

.secondRowContainer {
  display: flex;
  height: fit-content;
  max-height: 65px;
  overflow: hidden;
}

.thirdRowContainer {
  max-height: 70px;
}

.buttonSpacing {
  padding-right: 10px;
}
.buttonGroup {
  display: flex;
  padding-bottom: 5px;
}

.eventTextContainer {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.pageContainer {
  font-weight: bold;
  text-align: left;
}

.status {
  text-align: right;
  padding-right: 10px;
  font-size: 14px;
}

.dragged {
  outline: $govuk-focus-colour solid 3px;
  transform: rotate(-2deg);
}

.dropped {
  transform: rotate(0.5deg);
  outline: $govuk-focus-colour solid 2px;
}

@media (max-width: $max-width-tablet) {
  .container {
    padding-left: 2px;
    width: $container-width-tablet;
    font-size: 1rem;
    grid-template-columns: 06% 92%;
    .keyEventInformationContainer {
      font-size: 1rem;
      .nameContainer {
        width: 205px;
      }
    }
  }
}
