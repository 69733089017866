.notificationBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #1d70b8;
  z-index: 10;
  position: fixed;
  bottom: 0;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  width: 100%;
  color: #ffffff;
  font-family: 'nta', Arial, sans-serif;
  cursor: pointer;
  @media only screen and (min-width: 641px) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.notificationBell {
  margin-right: 0.5rem;
}
