.container {
  background: #1d70b8;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 1.14;

  @media screen and (min-width: 641px) {
    padding-top: 60px;
    padding-bottom: 60px;
    line-height: 1.25;
  }
}
