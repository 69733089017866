$header-height: 50px;
$bundle-information-bar-height: 56px;
$document-viewer-footer-height: 32px;
$visibility-milliseconds: 2500ms;

.container {
  width: 100%;
  height: calc(
    100vh - $header-height - $bundle-information-bar-height -
      $document-viewer-footer-height
  );
  overflow-y: auto;
  visibility: hidden;
  animation: cssAnimation 0s ease-in $visibility-milliseconds forwards;
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}

.loadingOverlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 0;
}
