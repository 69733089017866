.container {
  text-align: center;
}
.modal {
  position: relative;
  border: 1px solid rgb(204, 204, 204);
  background-color: rgb(255, 255, 255);

  outline: none;
  padding: 1rem;
  width: 80vw;
  max-width: 880px;
  max-height: 90vh;
  overflow-y: auto;
}
/* Close button */
.closeBtn {
  all: unset;
  color: #000;
  background-color: white;
  position: absolute;
  top: -1px;
  right: 1px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 0%;
  margin: 23px;
}
.closeBtn:hover,
.closeBtn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
.popUpTitle {
  margin-top: -3px;
}
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(100, 100, 100, 0.75);
  z-index: 9999;
}
