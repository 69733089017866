* {
  font-family: Arial, Helvetica, sans-serif;
}

.container {
  font-size: 16px;
  line-height: 1.25;
  label > * {
    display: block;
  }
  @media only screen and (min-width: 641px) {
    font-size: 19px;
    line-height: 1.3157894736842106;
  }
}

.containerWithError {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #d3351b;
  padding-left: 10px;
  label > * {
    display: block;
  }

  @media only screen and (min-width: 641px) {
    font-size: 19px;
    line-height: 1.3157894736842106;
  }
}

.reactSelectContainerGrey {
  background-color: #efefef;
}

.reactSelectContainerWhite {
  background-color: white;
}

.errorText {
  color: #d3351b;
  margin: 5px 0px 15px 0;
}

.labelText {
  margin: 0px 0px 4px 0;
}
