$header-height: 50px;
$bundle-information-bar-height: 56px;
$key-events-tab-height: 34px;
$document-information-padding: 20px;

.container {
  text-align: left;
  padding: 0.9375rem 0.9375rem 1.5rem 0.9375rem;
  background-color: #F0F0F0;  
  border-left: 1px solid #F0F0F0;
  display: block;
  height: 100%;
  position: relative;
  overflow: auto;
  display: grid;
  grid-template-rows:
    minmax(min-content, max-content) minmax(min-content, max-content)
    minmax(min-content, max-content);
  height: calc(
    100vh - $header-height - $bundle-information-bar-height -
      $key-events-tab-height - ($document-information-padding * 2)
  );
}

.noKeyEventsContainer {
  height: calc(
    100vh - $header-height - $bundle-information-bar-height -
      ($document-information-padding * 2)
  );
}

.divider {
  width: 100%;
  border: 0;
  border-top: 1px solid #F0F0F0;
  margin-bottom: 40px;
}

.heading {
  text-align: left;
}

.submitButton {
  align-self: flex-end;
  justify-self: center;
  width: 100%;
  button {
    width: 100%;
  }
}

.documentButtonGroup {
  height: fit-content;
  width: 200px;
  margin-bottom: 36px;

  button {
    margin-bottom: 5px;
    &:focus {
      margin-bottom: 2px;
    }
  }
}

.scroll {
  overflow: scroll;
}

.largeDocumentWarningContainer {
  display: flex;
  border: 4px #1c70b8 solid;
  border-radius: 4px;
  color: #1c70b8;
  padding: 10px 10px 10px 10px;
  margin-bottom: 20px;
  .largeDocumentWarningIcon {
    width: 40px;
    height: 40px;
    margin-bottom: auto;
    margin-right: 6px;
  }
  .largeDocumentWarningText {
    margin-bottom: auto;
    margin-top: auto;
    font-size: large;
  }
}
