.container {
  max-width: 39.375rem;
  /* Remove right hand margin when error is triggered */
  label {
    margin-right: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    width: 100%;
  }
}
