$header-height: 50px;
$bundle-information-bar-height: 60px;
$tab-height: 15px;
$tab-padding: 8px;
$footer: 38px;

.container {
  text-align: center;
  height: calc(
    100vh - $header-height - $bundle-information-bar-height - $tab-height -
      $tab-padding - $footer
  );
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;

  .informationContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 1rem;
    line-height: 1.7rem;

    .informationHeader {
      width: 100%;
      margin-top: 0rem;
    }

    .informationText {
      margin: 0;
      width: 70%;
    }
  }
}
