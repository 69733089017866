$max-width-tablet: 1368px;
$container-width-tablet: 233px;

.container {
  display: block;
  height: 5rem;
  width: 90%;
  font-family: Arial, Helvetica, sans-serif;

  .listItemCount {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    height: 2rem;
    font-weight: bold;
    font-size: large;
    white-space: nowrap;
  }
}

@media (max-width: $max-width-tablet) {
  .container {
    width: $container-width-tablet;
    .listItemCount {
      padding: 0;
    }
  }
}
