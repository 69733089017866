.form > * {
  display: block;
  margin: 30px 0;
}

.container {
  padding-top: 20px;
}

.submitButtonGroup {
  display: flex;
  margin-top: 2.5em;
}

.errorSummary {
  color: #0b0c0c;
  padding: 15px;
  border: 4px solid #d4351c;
  &:focus {
    outline: 3px solid #ffdd00;
    outline-offset: 0;
  }
  @media only screen and (min-width: 641px) {
    padding: 20px;
    border: 5px solid #d4351c;
  }
  margin-bottom: 20px;
  @media only screen and (min-width: 641px) {
    margin-bottom: 30px;
  }
  .paragraphText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 19px;
    line-height: 1.3157894736842106;
  }
}

.spinner {
  margin-left: 10px;
  margin-top: 5px;
}
