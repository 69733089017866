.container {
  padding-top: 38px;
  line-height: 1.14;
  @media screen and (min-width: 641px) {
    line-height: 1.25;
  }
  table {
    caption {
      height: 1px;
      border: none;
      width: 1px;
      position: absolute;
      overflow: hidden;
      top: -10px;
    }
  }
  .errorSummary {
    color: #0b0c0c;
    padding: 15px;
    border: 4px solid #d4351c;
    margin-bottom: 20px;
    &:focus {
      outline: 3px solid #ffdd00;
      outline-offset: 0;
    }
    @media only screen and (min-width: 641px) {
      padding: 20px;
      border: 5px solid #d4351c;
      margin-bottom: 30px;
    }
  }
}
