.container {
  min-height: 174px;
  padding-top: 65px;
  line-height: 1.14;
  @media screen and (min-width: 641px) {
    line-height: 1.25;
  }
  table {
    caption {
      height: 1px;
      border: none;
      width: 1px;
      position: absolute;
      overflow: hidden;
      top: -10px;
    }
  }
}
