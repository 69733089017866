.container {
  text-align: center;
}
.popUpButtonDiv {
  text-align: center;
  position: relative;
}

.modal {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
  outline: none;
  padding: 40px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 880px;
  max-height: 60vh;
  width: 80vw;
}
/* Close button */
.closeBtn {
  all: unset;
  color: #000;
  background-color: white;
  position: absolute;
  top: -1px;
  right: 1px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 0%;
  margin: 23px;
}
.closeBtn:hover,
.closeBtn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
.popUp {
  max-width: 880px;
  padding: 40px 40px 40px 40px;
}

.popUpTitle {
  margin-top: -3px;
}

.popUpButtons {
  margin-right: 15px;
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(100, 100, 100, 0.75);
  z-index: 1;
}

.uploadDocumentDiv {
  height: 70px;
}

