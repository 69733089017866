.form > * {
  display: block;
  margin: 30px 0;
}

.backLink {
  margin-bottom: 20px;
  a:focus {
    border-bottom: 3px solid #0b0c0c;
    margin-bottom: 13px;
    outline: none;
    text-decoration: none;
  }
}

.submitButtonGroup {
  display: flex;
}

.spinner {
  margin-left: 10px;
  margin-top: 5px;
}
