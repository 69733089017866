.overlay__background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    box-sizing: border-box; /* required */
    width: 100%; /* required */
    flex: none; /* required */
  }
  
  .overlay__container {
    background-color: white;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    padding: 30px;
    width: fit-content;
    height: fit-content;
  }
  