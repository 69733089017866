@media screen and (min-width: 641px) {
  .textField {
    width: 100%;
  }
}

.modalContent {
  max-height: 50rem;

  width: 500px;

  div > form > div:nth-child(1) > div > span {
    font-weight: bold;
  }

  .modalButtonContainer {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0 auto;
    width: 50%;
    position: 'relative';
    text-align: center;
    button {
      margin-bottom: 0;
    }
  }

  .tagsContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: flex-end;
  }

  .pagesContainer {
    margin-right: 10px;
    width: 175px;
  }

  .textField {
    width: 100%;
    textarea {
      resize: none;
    }
  }
  div > form > h2 {
    margin-bottom: 0px;
  }

  .popUpButtonDiv {
    display: flex;
    justify-content: center;
  }
}
