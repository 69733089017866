.modalContent {
  padding: 1.75rem;
  max-height: 40rem;
  overflow-y: auto;

  .modalButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0 auto;
    width: 50%;
    button {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }

  .tableContainer {
    text-align: center;
    padding-right: 1rem;
    padding-bottom: 0;
    margin-top: 1rem;
    width: 100%;
    min-height: auto;
    background-color: #fff;
    table {
      caption {
        height: 1px;
        border: none;
        width: 1px;
        position: absolute;
        overflow: hidden;
        top: -10px;
      }
    }
  }
}
