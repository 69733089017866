.container {
  height: 15rem;
  width: 100%;
  border: 1px dashed black;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #fff;
  .dropzone {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
