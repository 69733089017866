.container {
  text-align: left;
  margin-top: 10px;
}

.backLink {
  padding-left: 0.3rem;
  padding-bottom: 1px;
}

.tabPanel {
  border-top: 1px solid #bfc1c3 !important;
  padding: 0px !important;
  @media only screen and (min-width: 641px) {
    position: relative;
    z-index: 0;
    margin-top: -1px;
  }
}

.hideWebViewer {
  visibility: hidden;
  height: 0;
  width: 0;
}

.tab {
  @media only screen and (min-width: 641px) {
    padding-bottom: 17px !important;
    border-bottom: 0px !important;
  }
  @media only screen and (max-width: 1243px) and (min-width: 641px) {
    border-bottom: 1px solid #bfc1c3 !important;
  }
}

.tabListRow {
  margin: 0px !important;
  position: relative;
  z-index: 1;
}

.tabListColumn {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}
.tabList {
  @media only screen and (min-width: 641px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
