.errorContainer {
  margin-top: 20px;
  padding: 25px 25px 25px 25px;
  border: solid 5px #d3351b;
  max-width: 46.4rem;
  p {
    color: #d3351b;
    text-decoration: underline;
    font-size: 19px;
  }
}
