$tooltip-color: #000000;
$fade-duration-milliseconds: 300ms;
$fade-delay-milliseconds: 800ms;

.container {
  position: relative;
  display: inline-block;
  &:hover > .tooltip {
    opacity: 1;
  }
  .tooltip {
    position: absolute;
    top: 5%;
    transform: translateX(calc(-100% - 15px));
    background-color: $tooltip-color;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    z-index: 1;
    min-width: fit-content;
    height: 18px;
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
    transition-duration: $fade-duration-milliseconds;
    transition-delay: $fade-delay-milliseconds;
    &::after {
      content: '';
      position: absolute;
      right: -10px;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 10px solid $tooltip-color;
      border-bottom: 5px solid transparent;
    }
  }
}
