.headerWrapper {
  display: flex;
  justify-content: center;
  background-color: #0b0c0c;
}

.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  width: 100%;
  height: 50px;
  margin-right: max(15px, calc(15px + env(safe-area-inset-right)));
  margin-left: max(15px, calc(15px + env(safe-area-inset-left)));

  @media only screen and (min-width: 641px) {
    margin-right: max(30px, calc(15px + env(safe-area-inset-right)));
    margin-left: max(30px, calc(15px + env(safe-area-inset-left)));
  }
}

.headerLogo {
  font-size: 24px;
  font-weight: 800;
  color: #ffffff;
  text-decoration: none;
  text-decoration-skip-ink: none;

  @media only screen and (min-width: 641px) {
    font-size: 30px;
  }

  &:link {
    color: #ffffff;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.1em;
    text-decoration-thickness: 3px;
  }

  &:focus {
    color: #0b0c0c;
    outline: 3px solid #fd0;
    background-color: #fd0;
    text-decoration: none;
    box-decoration-break: clone;
  }

  &:active {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-underline-offset: 0.1em;
    text-decoration-thickness: 3px;
  }

  &:focus:active {
    color: #0b0c0c;
    text-decoration: none;
  }

  &:visited {
    color: #ffffff;
  }
}

.headerNavigation {
  display: flex;
  align-self: stretch;
  position: relative;
  margin-right: -15px;

  @media only screen and (min-width: 641px) {
    margin-right: -30px;
  }

  @media only screen and (min-width: 960px) {
    margin-right: 0;
  }
}

.headerNavigationToggleButton {
  display: block;
  position: relative;
  align-self: stretch;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-decoration-skip-ink: none;

  @media only screen and (min-width: 641px) {
    font-size: 16px;
  }

  &:after {
    content: '';
    height: 3px;
    left: 20px;
    position: absolute;
    right: 20px;
    bottom: 0;
    top: auto;
    background: none;
  }

  span {
    border-left: 1px solid #7c8185;
    padding: 6px 20px;

    &:before {
      content: '';
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      display: inline-block;
      height: 8px;
      margin: 0 10px 0 2px;
      vertical-align: middle;
      width: 8px;
      transform: translateY(-35%) rotate(45deg) scale(1);
    }
  }

  &:hover:not(.headerNavigationToggleButtonIsOpen) {
    color: #b1b4b6;

    &:after {
      background: #b1b4b6;
    }

    span {
      &:before {
        border-color: #b1b4b6;
      }
    }
  }
}

.headerNavigationToggleButtonIsOpen {
  background: #f3f2f1;
  color: #1d70b8;

  &:after {
    background: #1d70b8;
  }

  span {
    border-color: #f3f2f1;

    &:before {
      border-color: #1d70b8;
      transform: rotate(225deg);
    }
  }
}

.headerNavigationMenu {
  position: absolute;
  top: 50px;
  right: 0;
  width: 300px;
  height: 150px;
  background: #f3f2f1;
  text-align: right;
  padding: 20px;
  font-size: 14px;
  font-weight: 600;

  @media only screen and (min-width: 641px) {
    font-size: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 10px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.linkButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.headerBottomBorder {
  border-bottom: 10px solid #1d70b8;
  max-width: 960px;
  margin: 0 auto;
  width: calc(100% - 30px);

  @media only screen and (min-width: 641px) {
    width: calc(100% - 60px);
  }
}
