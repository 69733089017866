$govuk-text-colour: #0b0c0c;
$govuk-secondary-text-colour: #505a5f;
$govuk-brand-colour: #1d70b8;
$govuk-border-colour: #b1b4b6;
$govuk-link-colour: #1d70b8;
$govuk-focus-colour: #ffdd00;
$drag-handle-colour: #667080;
$tint-red-background: #fcd7d2;
$tint-red-colour: #932915;
$white: #fff;
$loading: #f8f9fa;

$transparent: #ffdd0000;
$fade-milliseconds: 8000ms;
$drag-milliseconds: 400ms;
$outline-milliseconds: 100ms;
$outline-fade-milliseconds: 5000ms;

$max-width-tablet: 1368px;
$container-width-tablet: 238px;

.container {
  display: inline-block;
  padding-left: 8px;
  width: 281px;
  height: 4.2rem;
  background-color: $white;
  border: 1px solid $govuk-border-colour;
  border-radius: 3px;
  box-shadow: 0 4px 6px -2px $govuk-border-colour;
  transition: transform $drag-milliseconds;
  transition: outline $outline-milliseconds;
}

.documentInformationContainer {
  display: grid;
  grid-template-rows: 50% 50%;
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.025rem;
  &:focus {
    outline: $govuk-focus-colour solid 3px;
  }
}

.nameContainer {
  font-weight: bold;
  font-size: 1.1rem;
  color: $govuk-link-colour;
  height: 83%;
  line-height: 2.6rem;
  width: 15rem;
  max-width: min-content;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid $govuk-link-colour;
}

.firstRowContainer {
  display: flex;
}

.largeDocumentWarningIcon {
  display: flex;
  justify-content: center;
  width: 1.8rem;
  color: #1c70b8;
  height: 1.5rem;
  margin-top: 5px;
  margin-left: auto;
}

.largeDocumentWarningIconSelected {
  display: flex;
  justify-content: center;
  width: 1.8rem;
  color: white;
  height: 1.5rem;
  margin-top: 5px;
  margin-left: auto;
}

.reviewedKeyEventsIcon {
  display: flex;
  justify-content: center;
  width: 1.8rem;
  color: #1c70b8;
  height: 1.5rem;
  margin-top: 5px;
  margin-left: auto;
}

.reviewedKeyEventsIconSelected {
  display: flex;
  justify-content: center;
  width: 1.8rem;
  color: white;
  height: 1.5rem;
  margin-top: 5px;
  margin-left: auto;
}

.statusContainer {
  display: grid;
  grid-template-columns: 42% 58%;
  text-align: left;
  line-height: 1.5rem;
  height: 80%;
  color: $govuk-text-colour;
}

.status {
  text-align: right;
  padding-right: 6px;
}

.corrupt {
  color: $tint-red-colour;
  background-color: $tint-red-background;
}

.corruptName {
  color: $tint-red-colour;
  border-bottom: 1px solid $tint-red-colour;
}

.corruptBorder {
  border: 1px solid $tint-red-colour;
}

.selected {
  background-color: $govuk-brand-colour;
  color: $white;
}

.selectedDragHandle {
  color: $white;
}

.selectedName {
  color: $white;
  border-bottom: 1px solid $white;
}

.loading {
  background-color: $loading;
  pointer-events: none;
}

.addedFromModal {
  animation: outlineFadeOut $outline-fade-milliseconds;
}

.loadingName {
  color: $govuk-text-colour;
  border-bottom: 0;
}

@keyframes outlineFadeOut {
  from {
    outline: $govuk-brand-colour solid 3px;
  }
  to {
    outline: $transparent solid 5px;
  }
}

.dragged {
  outline: $govuk-focus-colour solid 3px;
  transform: rotate(-2deg);
}

.dropped {
  transform: rotate(0.5deg);
  outline: $govuk-focus-colour solid 2px;
}

@media (max-width: $max-width-tablet) {
  .container {
    padding-left: 2px;
    width: $container-width-tablet;
    font-size: 0.9rem;
    grid-template-columns: 06% 92%;
    .documentInformationContainer {
      font-size: 0.9rem;
      .nameContainer {
        width: 205px;
      }
      .statusContainer {
        display: grid;
        grid-template-columns: 32% 68%;
        .status {
          text-align: right;
          padding-right: 6px;
        }
      }
    }
  }
}
