$scrollbar-width-y: 15px;

$toggle-milliseconds: 250ms;
$container-width: calc(330px + $scrollbar-width-y);
$toggle-width: 15px;

$container-width-tablet: calc(260px + $scrollbar-width-y);
$toggle-width-tablet: 10px;
$max-width-tablet: 1368px;

.container {
  display: flex;
  flex-direction: row;
  margin-left: 0;
  transition: margin-left $toggle-milliseconds;
  width: $container-width;

  .toggleContainer {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $toggle-width;
    background-color: #c4c4c4;
    color: #fff;
  }

  .toggleContainer:hover {
    cursor: pointer;
  }
}

.containerClosed {
  margin-left: calc($toggle-width - $container-width);
}

@media (max-width: $max-width-tablet) {
  .container {
    width: $container-width-tablet;

    .toggleContainer {
      width: $toggle-width-tablet;
    }
  }

  .containerClosed {
    margin-left: calc($toggle-width-tablet - $container-width-tablet);
  }
}
