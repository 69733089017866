$govuk-brand-colour: #1d70b8;
$white: #fff;
$link-focus-color: #fedd00;

.container {
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: $govuk-brand-colour;
  padding-left: 1rem;
  color: $white;
  height: 3.5rem;
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }
}

.backButton {
  all: unset;
  line-height: 1.1rem;
  text-decoration: underline;
  display: flex;
  cursor: pointer;
  width: fit-content;
  margin-right: 1rem;
  &:focus {
    all: unset;
    margin-right: 1rem;
    text-align: center;
    height: 1.5rem;
    background-color: $link-focus-color;
    color: #0b0c0c;
    border-bottom: 3px solid #0b0c0c;
    margin-bottom: -3px;
    outline: none;
    text-decoration: none;
    cursor: pointer;
  }
}

.customerNameMinWidth {
  max-width: 20rem;
}

.customerNameMidWidth {
  max-width: 30rem;
}

.proceedButton {
  font-size: 1.2rem;
  height: 84%;
  padding: 0.45rem 0.63rem;
}

.proceedButtonContainer {
  height: 80%;
  margin-top: 0.4rem;
  margin-left: auto;
  margin-right: 1rem;
}

$max-width-appeal-type: 1050px;
$max-width-business-area: 650px;

@media (min-width: $max-width-business-area) and (max-width: $max-width-appeal-type) {
  .appealType {
    display: none;
  }
}

@media (max-width: $max-width-business-area) {
  .appealType {
    display: none;
  }
  .businessArea {
    display: none;
  }
}
